<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="isHouse"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_2columnWrapper">
                <div class="bl_2columnWrapper_main">
                    <section ref="jest-public-header">
                        <h3 class="el_lv3Heading">ご案内</h3>
                        <p v-if="isHouse" class="align_justify"> 本サイトでは建築物省エネ法に基づく「住宅に関する省エネルギー基準に準拠したプログラム」等で必要となる住宅部品等の情報を掲載しております。一覧ページから確認したいカテゴリーを選択すると、登録された事業者等の情報提供ページへ移動します。なお、本検索サイトの<span class="underline">リンク先における各事業者等の情報提供内容については、各事業者の責任において記載</span>されております。リンク先の記載内容に関するご質問は、各事業者等の情報提供ページに記載のある問い合わせ先に直接お問い合わせください。</p>
                        <p v-else class="align_justify"> 本サイトでは、建築物のエネルギー消費性能の向上に関する法律（以下「建築物省エネ法」という。）に基づき、建築物の⼀次エネルギー消費量を算出する際に必要となる⾮住宅の建材‧設備‧部品等（以下「設備機器等」という。）の情報を掲載しております。リンク⼀覧ページの各設備機器等をクリックすると、登録された事業者等の情報提供ページへ移動します。なお、本検索サイトの<span class="underline">リンク先における各事業者等の情報提供内容については、各事業者の責任において記載</span>されております。リンク先の記載内容に関するご質問は、各事業者等の情報提供ページに記載のある問い合わせ先に直接お問い合わせください。</p>
                    </section>
                    <section>
                        <h3>住宅確認方法の区分について</h3>
                        <p
                            v-if="isHouse"
                            class="align_justify"
                        >
                             各事業者等の情報提供ページに記載されている「性能確認方法の区分」は、以下の記号と対応しております。また、形状等で性能確認が可能な建材等についての性能確認区分は「―」で示しております。 なお、ここでいう「生産品質」と「試験品質」の詳細につきましては「
                            <a
                                href="/pdf/一次エネ機器性能確認ガイドライン20130601.pdf"
                                target="house_guideline"
                            >
                                低炭素住宅の認定に係る設備機器の性能確認方法に関するガイドライン
                            </a>
                            」をご確認ください。
                        </p>
                        <p
                            v-else
                            class="align_justify"
                        >
                            各事業者の情報掲載ページにおいては、試験品質についての性能証明書類の添付を条件としておりますが、⽣産品質を含めた性能確認⽅法として下表による区分もございます。 なお、「⽣産品質」と「試験品質」の詳細につきましては
                            <a
                                href="/pdf/建築物省エネ法に係る設備機器の性能確認方法等に関するガイドライン.pdf"
                                target="house_guideline"
                            >
                                「建築物省エネ法に係る設備機器の性能確認⽅法等に関するガイドライン」
                            </a>
                            をご確認ください。
                        </p>
                        <table width="100%">
                            <thead>
                                <tr>
                                    <th
                                        rowspan="2"
                                        colspan="2"
                                    />
                                    <th colspan="3">生産品質</th>
                                </tr>
                                <tr>
                                    <th>ISO登録工場又は<br />JIS認証取得工場</th>
                                    <th>第三者生産品質審査<br />機関で審査実施</th>
                                    <th>自己適合宣言<br />（JIS Q 17050-1）</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th rowspan="3" class="uq_wd_18">試験品質</th>
                                    <th>第三者試験機関<br />で試験実施</th>
                                    <td colspan="2" rowspan="2">A</td>
                                    <td rowspan="2">B－１</td>
                                </tr>
                                <tr>
                                    <th>第三者試験等審査<br />機関で審査実施</th>
                                </tr>
                                <tr>
                                    <th>自己適合宣言<br />(JIS Q1000等)</th>
                                    <td colspan="2">B－２</td>
                                    <td>C</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                                        
                </div>
                <div class="bl_2columnWrapper_sideBar">
                    <section
                        ref="jest-public-information"
                        class="uq_update"
                    >
                        <h3>お知らせ</h3>
                        <ul v-if="informations.length !== 0" class="bl_news">
                            <li
                                v-for="(item, index) in informations"
                                :key="'informations' + index"
                                class="bl_news_list"
                            >
                                <time>{{date(item.date)}}</time>
                                <span class="bl_news_list_title">{{item.title}}</span><br>
                                <span class="bl_news_list_text">{{item.text}}</span>
                            </li>
                        </ul>
                        <p v-else>現在、お知らせはありません。</p>
                    </section>
                    <section
                        ref="jest-new-link"
                        class="uq_update">
                        <h3>新着リンク情報</h3>
                        <ul v-if="newLinks.length !== 0" class="bl_news">
                            <li
                                v-for="(item, index) in newLinks"
                                :key="'newLinks' + index"
                                class="bl_news_list"
                            >
                                <time>{{date(item.updated_at) }}</time>
                                <p class="bl_news_list_text">{{item.string_category_code}}</p>
                                <span class="bl_news_list_title">{{item.organization_name}}</span>
                            </li>
                        </ul>
                        <p v-else>現在、新着情報はありません。</p>
                    </section>
                </div>
            </div>
            <div class="ly_buttonBox">
                <app-button
                    name="一覧へ"
                    @click="toLinks"
                    ref="jest-public-links"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P010 案内ページ（住宅版）
 * P020 案内ページ（非住宅版）
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「情報提供事業者ログイン」ボタン押下時 ...  表示画面に応じたログイン画面へ遷移
 * 「一覧へ」ボタン押下時 ... 表示画面に応じたカテゴリー一覧へ遷移
 */
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { move } from '@/util'

export default {
    name:"PublicIndex",
    mounted(){
        this.updateNewLinks(this.typeString)
        this.updateInformations({
            show_users:['all', 'general'],
            show_type:['common', this.typeString],
        })
        //Google Analyticsの承諾イベント発行
        this.$gtag.pageview('/' + this.typeString)
    },
    computed:{
        ...mapGetters({
            newLinks:'links/newLinks',
            informations:'informations/informations'
        }),
        isHouse(){
            return this.$route.path.includes('house')
        },
        typeString(){
            return this.isHouse ? 'house' : 'building'
        },
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        },
        /**
         * 日付文字列を「YYYY年MM月DD日」の形式に成形
         */
        date(){
            return function (dateString) {
                return dateString ? moment(dateString).format('YYYY年MM月DD日') : '-'
            }
        },
    },
    methods:{
        ...mapActions({
            updateNewLinks:'links/updateNewLinks',
            updateInformations:'informations/getInformations',
        }),
        toLinks(){
            move(this.$router, this.$route.path + '/links')
        }
    }
}
</script>

<style scoped>
.uq_update{
    margin-bottom: 32px;
    padding-bottom: 32px;
}
.uq_update+section{
    margin-top: 0;
    padding-top: 16px;
}
.bl_2columnWrapper_sideBar>section{
    margin-bottom: 0;
}

table th{
    padding: 4px;
}

table td{
    text-align: center;
}
table tbody th:not([rowspan]){
    width: auto;
}
.uq_wd_18{
    max-width: 20px;
    padding: 4px;
}
.underline{
    text-decoration: underline;
}
</style>