<template>
    <default-template
        :title="'温熱・省エネ設備機器ポータルサイト' + title"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="isHouse"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="showingLevelText"
        class="uq_evaluatorLink"
    >
        <div class="bl_underHeader ly_underHeader">
            <h2 class="el_lv2Heading">事業者リンク⼀覧</h2>
            <hr class="hp_flexspacer"/>
            <p>ご覧になりたい事業者等の名称をクリックしてください。<br>事業者の案内ページが別ウィンドウで開きます。</p>
        </div>
        <error-list />
        <div class="uq_linksOrgWrapper">
            <div v-if="!authorized"
                class="uq_loginEvaluator">
                <h4>評価機関用</h4>
                <app-text
                    name="number"
                    v-model="number"
                    label="評価機関番号"
                    class="formrow"
                />
                <div class="formrow">
                    <app-label
                        name="password"
                        label="パスワード"
                    />
                    <input
                        type="password"
                        name="password"
                        v-model="password"
                        class="rowcontents"
                    />
                </div>
                <app-button
                    name="表示"
                    @click="showLinksForEvaluator"
                    ref="jest-public-links-show"
                />
            </div>
            <div class="uq_OrgList">
                <div v-if="!isInProgress"
                    class="bl_linkLists_table_outer">
                    <table v-if="uniqueLinks.length !== 0" 
                        class=linkLists_table>
                        <tr
                            v-for="(item, index) in uniqueLinks"
                            :key="'category' + index"
                            :ref="'category' + index"
                        >
                            <td>{{index + 1}}</td>
                            <td>
                                <a
                                    :href="item.url"
                                    target="_blank"
                                >
                                    {{item.organization_name}}
                                </a>
                            </td>
                        </tr>
                    </table>
                    <p
                        v-else
                        ref="category-undefined"
                    >
                        登録されている事業者は、現在ありません。
                    </p>
                </div>
                <div
                    v-if="authorized"
                    ref="jest-authorized"
                    class="uq_authorized"
                >
                    <h3>評価機関⽤</h3>
                    <p>評価機関⽤リンクです。事業者の名称をクリックしてください。<br>事業者の案内ページが別ウィンドウで開きます。</p>
                    <div class="bl_linkLists_table_outer">
                        <table v-if="linksForEvaluator.length !== 0"
                            class="linkLists_table">
                            <tr
                                v-for="(item, index) in uniqueLinksForEvaluator"
                                :key="'categoryForEvaluator' + index"
                                :ref="'category-for-evaluator' + index"
                            >
                                <td>{{index + 1}}</td>
                                <td>
                                    <a
                                        :href="item.url"
                                        target="_blank"
                                    >
                                        {{item.organization_name}} {{item.memo ? item.memo : ''}}
                                    </a>
                                </td>
                            </tr>
                        </table>
                        <p
                            v-else
                            ref="category-for-evaluator-undefined"
                        >
                            登録されている事業者は、現在ありません。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="ly_buttonBox">
            <app-button
                name="戻る"
                class="cancel"
                @click="back"
                ref="jest-public-links-back"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * P011 カテゴリー一覧（住宅版）
 * P021 カテゴリー一覧（非住宅版）
 *
 * [算出プロパティ]
 * title ... アクセスされたページの種類に合わせてタイトルを出し分ける
 *
 * [イベント処理]
 * 「情報提供事業者ログイン」ボタン押下時 ...  表示画面に応じたログイン画面へ遷移
 * 「一覧へ」ボタン押下時 ... 表示画面に応じたカテゴリー一覧へ遷移
 */
import { move } from '@/util'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import Progress from '@/mixins/progress.js'
import { mapComputedProperties } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'

export default {
    name:"PublicLinksOrgnaizations",
    data(){
        return {
            authorized:false,
        }
    },
    mixins:[Progress],
    components:{
        ErrorList,
    },
    mounted(){
        this.updateList(false)
    },
    computed:{
        ...mapGetters({
            links:'links/links',
            linksForEvaluator:'links/linksForEvaluator',
            selectedCategoryName:'links/selectedCategoryName',
            showingLevel:'links/showingLevel',
            selectedCategoryCode:'links/selectedCategoryCode',
            data:'links/evaluatorAuthParameter',
        }),
        ...mapComputedProperties([
            'number',
            'password'
        ]),
        isHouse(){
            return this.$route.path.includes('house')
        },
        title(){
            return this.isHouse ? '(住宅版)' : '(非住宅版)'
        },
        showingLevelText(){
            let text = this.showingLevel.level1 +  ' - ' + this.showingLevel.level2
            if (this.showingLevel.level3) {
                text = text + ' - ' + this.showingLevel.level3
            }
            text = text + ' - ' + this.selectedCategoryName
            return text
        },
        uniqueLinks(){
            const formattedLinks = cloneDeep(this.links)
            return formattedLinks.filter((x, i, self) => {
                // indexOf()だと特定の要素だけ一致するという判定が使えない
                return self.findIndex(item => {
                    return x.url === item.url && x.organization_name === item.organization_name
                }) === i
            })
        },
        uniqueLinksForEvaluator(){
            const formattedLinksForEvaluator = cloneDeep(this.linksForEvaluator)
            return formattedLinksForEvaluator.filter((x, i, self) => {
                // indexOf()だと特定の要素だけ一致するという判定が使えない
                return self.findIndex(item => {
                    return x.url === item.url && x.organization_name === item.organization_name
                }) === i
            })
        }
    },
    methods:{
        ...mapActions({
            updateLinks:'links/updateLinks',
            updateWindowLinks:'links/updateWindowLinks',
            updateDoorLinks:'links/updateDoorLinks',
            update:'links/update',
            clearError:'error/clear',
            updateError:'error/update',
        }),
        async updateList(for_evaluator){
            this.clearError()
            const loader = this.$loading.show()
            this.startProgress()
            const type = this.isHouse ? 'house' : 'building'
            if (this.selectedCategoryCode == 1048 || this.selectedCategoryCode == 1049) {
                await this.updateLinksType({for_evaluator})
                    .then(response => {
                        if(for_evaluator && response.status === 200){
                            this.authorized = true
                        } else if (response.status !== 200) {
                            this.updateError(response)
                        }
                    })
            } else {
                await this.updateLinks({type, for_evaluator})
                    .then(response => {
                        if(for_evaluator && response.status === 200){
                            this.authorized = true
                        } else if (response.status !== 200) {
                            this.updateError(response)
                        }
                    })
            }
            this.finishProgress()
            loader.hide()
        },
        updateLinksType({for_evaluator}){
            const updateFunction = {
                1048:this.updateWindowLinks,
                1049:this.updateDoorLinks,
            }[this.selectedCategoryCode]({for_evaluator})

            return updateFunction
        },
        showLinksForEvaluator(){
            this.updateList(true)
        },
        back(){
            if (this.selectedCategoryName === 'コージェネレーション') {
                move(this.$router, '/cogenerations')
            } else if (this.selectedCategoryName === 'ハイブリッド給湯機') {
                move(this.$router, '/hybrid_water_heaters')
            } else if (this.selectedCategoryName === '電気ヒートポンプ給湯機') {
                move(this.$router, '/electric_heat_pumps')
            } else if (this.selectedCategoryCode == 1048) {
                move(this.$router, '/house/links/opening/window')
            } else if (this.selectedCategoryCode == 1049) {
                move(this.$router, '/house/links/opening/door')
            } else if (this.selectedCategoryCode >= 2004 && this.selectedCategoryCode <= 2021) {
                move(this.$router, '/building/links/opening')
            } else {
                move(this.$router, this.$route.path.replace('/organizations', ''))
            }
        },
    }
}
</script>

<style scoped>
.uq_evaluatorLink >>> .bl_container {
    min-height: 80vh;
    position: relative;
}
.uq_linksOrgWrapper{
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
}
.uq_loginEvaluator{
    display: flex;
    flex-direction: column;
    width: 228px;
    height: fit-content;
    margin-left: auto;
    padding: 30px;
    background-color: #F1F4EC;
}
.uq_loginEvaluator h4{
    margin: 0 0 8px;
    border-bottom: 1px solid #4A6533;
}

.uq_loginEvaluator >>> label.el_label{
    color: #4A6533;
}
.uq_loginEvaluator >>> button{
    width: 100%;
    height: 32px;
    margin: 20px 0 0 0;
}
.uq_OrgList{
    margin-right: 16px;
}
.uq_authorized{
    margin: 30px 0 0 0;
    border-top: 1px solid #4A6533;
}
.linkLists_table a{
    display: inline-flex;
    align-items: center;
}
.linkLists_table a:after{
    content:"";
    display: inline-flex;
    width: 14px;
    height: 14px;
    margin-left: 4px;
    background: url(/img/icon_set.png);
    background-repeat: no-repeat;
    background-size: auto 14px;
    background-position-x: 75%;
}
/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 950px) {
    .uq_loginEvaluator{
        position: unset;
        float: right;
    }
}
</style>